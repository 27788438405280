import React from 'react'
import CardItem from './CardItem'
import './Cards.css'

function Cards() {
    return (
        <div className='cards'>
            <h1>Our initiatives</h1>
        </div>
    )
}

export default Cards