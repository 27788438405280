import React from 'react';
import './Boxes.css';

function Boxes() {
  return (
    
    <div className="boxes" style={{ paddingBottom: '50px' }}>
      <div className="boxes">
        <div className="box">
          <img src="images/awareness.jpeg" alt="Awareness" />
          <h3>Raising Awareness</h3>
          <p>
            Helping raise awareness to rare diseases so people know how to interact with those affected, what they could possibly have, and how they can help.
          </p>
        </div>

        <div className="box">
          <img src="images/collaboration1.jpeg" alt="Collaboration" />
          <h3>Collaboration</h3>
          <p>
            We will collaborate with organizations like CORD (Canadian Organization for Rare Disorders) and NORD (National Organization for Rare Disorders) that have similar objectives for the development of rare diseases.
          </p>
        </div>

        <div className="box">
          <img src="images/educate.jpeg" alt="Educate"  />
          <h3>Educate</h3>
          <p>
            Helping educate people on rare diseases so that they know what is affecting millions of people all around the world. While there is a plethora of information regarding rare diseases, we will concise what you need to know.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Boxes;
