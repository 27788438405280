import React from 'react';
import '../../App.css'
import HeroSection from '../HeroSection' 
import Cards from '../Cards'   
import Boxes from '../Boxes'
import AboutUs from '../AboutUs';
import CountdownTimer from './CountdownTimer';

function Home() {
    return (
        <>
            <HeroSection />
            {/* <CountdownTimer 
            countdownTimestampMs={new Date('2024-02-29T00:00:00.000Z').getTime()}
            /> */}
            <AboutUs/>
            {/* <Cards /> */}
            <Cards />
            <Boxes />
        </>
    );
}

export default Home;