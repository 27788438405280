import React from 'react';
import './Footer.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import tmurd from "./tmurd.png";

function Footer() {
  return (
    <div className='footer-container'>
      <section className='footer-subscription'>
        <p className='footer-subscription-heading'>
          Join the RARE newsletter to stay up to date on all things rare diseases!
        </p>
        <p className='footer-subscription-text'>
          You can unsubscribe at any time.
        </p>
        <div className='input-areas'>
          <form>
            <input
              className='footer-input'
              name='email'
              type='email'
              placeholder='Your Email'
            />
            <Button buttonStyle='btn--outline'>Subscribe</Button>
          </form>
        </div>
      </section>
      <div class='footer-links'>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <Link to='ContactUs'>Contact Us</Link> 
            <Link to='/'>Get Involved</Link>
            {/* <Link to='/'>Destinations</Link>
            <Link to='/'>Sponsorships</Link> */}
          </div>
        </div>
        <div className='footer-link-wrapper'>
        </div>
      </div>
      <section class='social-media'>
        <div class='social-media-wrap'>
          <div class='footer-logo'>
            <Link to='/' className='social-logo'>
              TMURD
              <img src={tmurd} alt="tmurd" width="40" height="40" />
            </Link>
          </div>
          {/* <small class='website-rights'>TMURD 2023</small> */}
          <div class='social-icons'>
            <Link
              class='social-icon-link spotify'
              to='https://open.spotify.com/show/1k11x7kxNLUeuDlqpGALP2?si=28c6ae9922984b16'
              target='_blank'
              aria-label='Spotify'
            >
              <i class='fab fa-spotify' />
            </Link>
            <Link
              class='social-icon-link instagram'
              to='https://www.instagram.com/rarediseasestmu/'
              target='_blank'
              aria-label='Instagram'
            >
              <i class='fab fa-instagram' />
            </Link>
            {/* <Link
              class='social-icon-link youtube'
              to='/'
              target='_blank'
              aria-label='Youtube'
            >
              <i class='fab fa-youtube' />
            </Link> */}
            <Link
              class='social-icon-link twitter'
              to='/'
              target='_blank'
              aria-label='Twitter'
            >
              <i class='fab fa-twitter' />
            </Link>
            <Link
              class='social-icon-link linkedin'
              to='https://www.linkedin.com/company/tmu-rarediseasesclub/'
              target='_blank'
              aria-label='LinkedIn'
            >
              <i class='fab fa-linkedin' />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;