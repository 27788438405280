import React from 'react';
import '../../App.css';
import './Team.css';

function App() {
    const teamMembers = [
        {name: 'Bahar Taghizadeh', role: 'President', email: 'btaghizadeh@torontomu.ca', img: 'teamPhotos/Bahar1.jpeg' },
        {name : 'Marisa Chiaravalloti', role: 'Vice President', email: 'mchiaravalloti@torontomu.ca', img: 'teamPhotos/Marisa1.jpeg'},
        {name : 'Ornella Shaikovsky' , role: 'Event Director', email: 'oshaikovsky@torontomu.ca', img: 'teamPhotos/Ornella1.jpeg'},
        {name : 'Dominika Celej', role: 'Vp Communications', email: 'dominika.celej@torontomu.ca', img: 'teamPhotos/DominikaCelej.jpeg'},
        {name : 'Ghazal Ghazanfarpour', role: 'VP Operation', email: 'ghazal.ghazanfarpour@torontomu.ca', img: 'teamPhotos/Ghazal1.jpeg'},
        {name : 'Alacea Yerxa', role: 'VP Finance', email: 'alacea.yerxa@torontomu.ca', img: 'teamPhotos/Aaryaman1.jpeg'},
        {name : 'Connie Di Raimo', role: 'Media Director', email: 'Connie.diraimo@torontomu.ca', img: 'teamPhotos/Connie1.jpeg'},
        {name : 'Mai Aida Mbow', role: 'Graphic designer', email: 'mai.mbow@torontomu.ca' ,img: 'teamPhotos/Aida1.jpeg'},
        {name : 'Gabriel Jude',role: 'Software Developer', email: 'gabriel.jude@torontomu.ca',img: 'teamPhotos/Aaryaman1.jpeg'},
        {name : 'Shreya Nagah',role: 'Senior Representative', email: 'shreya.nagah@torontomu.ca',img: 'teamPhotos/Aaryaman1.jpeg'},
        {name : 'Angie Ndekwe', role: 'Volunteer', email: 'andekwe@torontomu.ca', img: 'teamPhotos/Aaryaman1.jpeg'},
        {name : 'Sommerly Ertl', role: 'Marketing Coordinator', email: 'sommerly.ertl@torontomu.ca', img: 'teamPhotos/Aaryaman1.jpeg'},
        
        // Add other team members here...
    ];

    return (
        <div className="team-page">
            <h1>Meet the team</h1>
            <div className="team-container">
                {teamMembers.map((member, index) => (
                    <div key={index} className="team-member">
                        <img src={member.img} alt={member.name} className="team-member-img" />
                        <h3 className="team-member-name">{member.name}</h3>
                        <h5 className="team-member-role">{member.role}</h5>
                        <p className="team-member-email">Email: {member.email}</p>
                        {/* <p className="team-member-task">Task: {member.task}</p> */}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default App;
