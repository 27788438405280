import React from "react";
import "../../App.css";
import "./TooRareStories.css";

export default function TooRareStories() {
  return (
    <>
      <hr style={{ position: 'relative', top: '-8px' }} />
      <h1>
        <center>
          <br></br>
          {"Breaking Barriers: Navigating Life with a Rare Condition - A Q and A Session"}
        </center>
      </h1>
      <div style={{ display: 'flex', justifyContent: 'center', position: 'relative', paddingBottom: '50px' , paddingTop: '50px'}}>
        <br></br>
        <img src="images/Anthony2.jpeg" alt="Anthony" style={{ height: '400px', width: '450px', boxShadow: '10px 10px 5px #ccc' }} />
      </div>

      <div className="question">
        <p>
          <center>Q: What is a rare disease?</center>
        </p>
      </div>
      <div className="answer">
        <p>
          <center>A: A rare disease is a condition that affects a small percentage of the population. It is often
            characterized by limited research and a lack of treatment options.</center>
        </p>
      </div>

      <div className="question">
        <p>
          <center>Q: Mr.Gilding, you suffer from Alopecia Areata a rare disease. -What is Alopecia Areata and
            how does the disease manifest itself?
          </center>
        </p>
      </div>
      <div className="answer">
        <p>
          <center>A: Alopecia Areata is an autoimmune disease of the hair follicles. The immune system wrongfully
            attacks the hair follicles, causing inflammation. This inflammation makes the hair fall out and
            prevents it from growing back. For some people, the hair falls out in patches, whereas others
            lose all the hair on their head (including eyebrows and eyelashes) and bodies.
          </center>
        </p>
      </div>

      <div className="question">
        <p>
          <center>Q: When is Alopecia Areata usually detected and when were you diagnosed with alopecia?</center>
        </p>
      </div>
      <div className="answer">
        <p>
          <center>A: Alopecia Areata affects people of all ages, though most people are diagnosed at an early age
            (childhood to early adulthood). Personally, I was diagnosed with Alopecia Areata at 14.</center>
        </p>
      </div>

      <div className="question">
        <p>
          <center>Q: How important is the early diagnosis for the course of the disease?</center>
        </p>
      </div>
      <div className="answer">
        <p>
          <center>A: Alopecia Areata is unpredictable and notably resistant to treatment. However, if we diagnose it
            and initiate treatment early, patients may see satisfactory regrowth of their hair, though this is
            not guaranteed. Recently, Janus Kinase (JAK) inhibitors have come forth as a very effective
            treatment for Alopecia Areata. While this is exciting, Health Canada has yet to approve this
            treatment, so patients with Alopecia Areata do not yet have access to them.
          </center>
        </p>
      </div>

      <div className="question">
        <p>
          <center>Q: How would you rate the level of information in the medical profession?</center>
        </p>
      </div>
      <div className="answer">
        <p>
          <center>A: Most physicians have at least heard ofAlopecia Areata. Family physicians are often the first to diagnose it, at which point they’ll refer the patient to a Dermatologist as they are the specialist physicians trained to manage the disease. Outside of the medical profession, the level of understanding of Alopecia Areata is quite low. That is where organizations like the Canadian Alopecia Areata Foundation (@canaaf) plays a pivotal role in educating the public and offering support services.</center>
        </p>
      </div>

      <div className="question">
        <p>
          <center>Q: After years of living with Alopecia, what did you learn about this diseases and how you handle that with your daily life?</center>
        </p>
      </div>
      <div className="answer">
        <p>
          <center>A: Initially, Alopecia Areata devastated me. It caused me significant anxiety and depression to the point that I couldn’t leave my home. It took the support of my family and mental health professionals for me to learn to cope with the disease and live my life in a way that was meaningful. Now, having lived with the disease for almost 9 years, I’m dedicated to raising awareness and slashing the stigma of this rare hair loss disorder. I’m currently pursuing a career as a dermatologist so that I can spend my life caring for people living with Alopecia Areata!</center>
        </p>
      </div>
      <br></br>
      <br></br>
      <br></br>
    </>
  );
}
